<template>
  <!-- Swiper -->
  <div class="swiper">
    <div class="swiper-wrapper">
      <!-- <div class="swiper-slide">hello</div> -->

      <slot></slot>
    </div>
    <!-- Add Pagination -->
    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  mounted() {
    var swiper = new Swiper(".swiper", {
      effect: "cube",
      grabCursor: true,
      loop: true,
      lazy:true,
      keyboard: {
        enabled: true,
      },
      autoplay: {
        delay: 1500,
        disableOnInteraction: false,
      },
      cubeEffect: {
        shadow: false,
        slideShadows: true,
        shadowOffset: 20,
        shadowScale: 0.94,
      },
      pagination: {
        el: ".swiper-pagination",
        // type:"progressbar",
        dynamicBullets: true,
        clickable: true,
      },
    });
  },
};
</script>
<style>
.swiper {
  width: 250px;
  height: fit-content;
  /* position: absolute; */
  /* left: 50%;
            top: 50%; */
  /* margin-left: -150px;
            margin-top: -150px; */
  /* margin: 1rem; */
  /* border: 1px solid; */
}
.swiper-slide{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide > img {
  width: 100%;
  background-position: center;
  background-size: cover;
  border-radius: .2rem;
}
.swiper-pagination {
  margin: -2rem auto;
}
@media (max-width: 500px) {
  .swiper {
    width: 70%;
    height: auto;
  }
}
</style>