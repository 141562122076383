<template>
  <!-- Swiper -->
  <div class="swiper">
    <div class="swiper-wrapper">
      <!-- <div class="swiper-slide">hello</div> -->

      <slot></slot>
    </div>
    <!-- Add Pagination -->
    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
export default { 
  mounted() {
    var swiper = new Swiper(".swiper", {
      effect: "cube",
      grabCursor: true,
      // loop: true,
      lazy:true,
      keyboard: {
        enabled: true,
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      cubeEffect: {
        shadow: false,
        slideShadows: true,
        shadowOffset: 20,
        shadowScale: 0.94,
      },
      pagination: {
        el: ".swiper-pagination",
        type:"progressbar",
      },
    });
  }, 
};
</script>
<style scoped >
.swiper {
  width: 400px;
  height: fit-content;
  /* border: 2px solid white; */
  
}
.swiper-slide{
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

}
.swiper-pagination {
  margin: -.5rem auto;
}

@media (max-width: 500px) {
  .swiper {
    width: 90%;
  }
}
</style> 