<template>
  <router-link to="/story">
    <!--  color-change-2x -->
    <section @click="openStory = !openStory" class="img-box" title="Open Story">
      <div class="img-space">
        <img
          src="../assets/LB.png"
          alt="Google Developer Groups"
          class="heartbeat"
        />
      </div>
    </section>
  </router-link>
  <transition name="openStory">
    <section v-if="openStory" class="story-hub">
      <story-swiper>
        <div v-for="s in stories" :key="s" class="swiper-slide">
          <div class="story-slide skeleton">
            <img class="story-img swiper-lazy" :data-src="s.story" alt="" />
            <div class="swiper-lazy-preloader"></div>
            <div class="story-header">
              <h4>{{ storyTime(s.time) }}</h4>
            </div>
          </div>
        </div>
      </story-swiper>
      <router-link to="/" class="close-story">
        <i @click="openStory = !openStory" class="bi bi-arrow-return-left"></i>
      </router-link>
      <!-- STORY TOOL BAR -->

      <!-- <i @click="openStory = !openStory" class="bi bi-x-lg close-story"></i> -->
    </section>
  </transition>
</template>
<script>
const dayjs = require("dayjs");
var calendar = require("dayjs/plugin/calendar");
dayjs.extend(calendar);
import storySwiper from "../components/story-swiper.vue";
// import dayjs from "dayjs";
export default {
  props:['stories'],
  components: { storySwiper },
  watch: {
    $route(to, from) {
      // console.log(to, from);
      if (to.name == undefined) this.openStory = false;
      if (to.name == "story" && from.name == undefined) this.openStory = true;
      // if(to.name != undefined) this.openStory = true
    },
  },
  // props: ["stories"],
  methods: {
    storyTime(time) {
      const result = dayjs(time).calendar();
      // console.log(result);
      return result;
    },
  },
  data() {
    return {
      openStory: false,
      logo: "",
    };
  },
};
</script>
<style>
.story-hub {
  width: 100%;
  height: 100%;
  /* background-color: var(--dark); */
  /* background-color: #2b2d42; */
  background-image: linear-gradient(to top, #09203f 0%, #537895 100%);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  top: 0;
  overflow-x: hidden;
  color: aliceblue;
  padding-top: 2rem;
}
.story-hub > h1 {
  margin-bottom: 2rem;
}

.story-text {
  width: 100%;
  min-height: 400px;
  background-color: var(--light);
  color: var(--dark);
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1.5;
  padding: 1rem;
  font-family: "Noto Kufi Arabic", sans-serif;
}
.story-slide {
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  position: relative;
}
@media (max-width: 500px) {
  .story-slide,
  .story-text {
    min-height: 324px;
  }
}
.story-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.story-header {
  width: 100%;
  background: rgb(0, 0, 0, 0.3);
  /* box-shadow: 0px 0rem 10px rgb(0, 0, 0, 0.3); */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  letter-spacing: 2px;
  top: 0;
  z-index: 3;
  padding: 1rem;
}
.close-story {
  margin: 2rem;
  cursor: pointer;
}
.openStory-enter-active {
  -webkit-animation: scale-in-center 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.openStory-leave-active {
  -webkit-animation: scale-out-center 0.15s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: scale-out-center 0.15s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  border-radius: 5rem;
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
</style>