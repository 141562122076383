<template>
  <header>
    <div class="profile-header">
      <story v-if="!spinner" :stories="data.stories"></story>
    </div>
    <div class="profile-bio">
      <h1>Lebancode</h1>

      <div v-if="spinner" class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p v-if="!spinner">{{ bio }}</p>
    </div>
    <div v-if="!spinner" class="social-media-box">
      <!-- <a href=""><i class="bi bi-translate"></i></a> -->

      <router-link to="/share" @click="openPage = !openPage"
        ><i class="bi bi-share-fill"></i
      ></router-link>

      <!-- <a href=""><i class="bi bi-search"></i></a> -->
      <i @click="notify" class="bi bi-bell a"></i>
      <router-link to="/contact" @click="openPage = !openPage"
        ><i class="bi bi-telephone-outbound-fill"></i
      ></router-link>
    </div>
  </header>
  <hr v-if="!spinner" />
  <main v-if="!spinner">
    <!-- TAGS and Links -->
    <section class="tags">
      <!-- TAGS -->
      <div class="tag-box">
        <i class="bi bi-hash"></i>
        <router-link to="/web_standards" @click="openPage = !openPage"
          >WEB Standards</router-link
        >
      </div>

      <div class="tag-box">
        <i class="bi bi-hash"></i>
        <router-link to="/faq" @click="openPage = !openPage">FAQ</router-link>
      </div>

      <div class="tag-box">
        <i class="bi bi-hash"></i>
        <router-link to="/crm" @click="openPage = !openPage">CRM</router-link>
      </div>

      <div class="tag-box">
        <i class="bi bi-hash"></i>
        <router-link to="/web_extensions" @click="openPage = !openPage"
          >WEB Extensions</router-link
        >
      </div>

      <div class="tag-box">
        <i class="bi bi-hash"></i>
        <router-link to="/business_emails" @click="openPage = !openPage"
          >Business Emails</router-link
        >
      </div>

      <div class="tag-box">
        <i class="bi bi-hash"></i>
        <router-link to="/pwa" @click="openPage = !openPage">PWA</router-link>
      </div>

      <div class="tag-box">
        <i class="bi bi-hash"></i>
        <router-link to="/partners_and_clients" @click="openPage = !openPage"
          >Partners and Clients</router-link
        >
      </div>

      <!-- LINKS  -->
      <div v-for="l in data.links" :key="l" class="link-box">
        <i class="bi bi-link-45deg"></i>
        <a :href="l.url" target="_blank">{{l.name}}</a>
      </div>
      <!-- <div class="link-box">
        <i class="bi bi-link-45deg"></i>
        <a href="https://mediaminds.club" target="_blank">Marketing</a>
      </div> -->
    </section>
  </main>

  <!-- VIEWS -->
  <section>
    <transition name="SlideModal">
      <modal v-if="openPage" :title="$route.name">
        <router-link to="/" @click="openPage = !openPage">
          <i class="bi bi-arrow-left back-icon"></i>
        </router-link>
        <template v-slot:body>
          <router-view :apk="data.profile.apk" :clients="data.clients" :faq="data.faq" :links="data.links" />
        </template>
      </modal>
    </transition>
  </section>
  <footer v-if="!spinner">
    <h6>Powered By Lebancode</h6>
  </footer>
</template>

<script>
import modal from "./components/modal.vue";
import accordion from "./components/accordion.vue";
import story from "./components/story.vue";
export default {
  name: "App",
  components: { modal, accordion, story },
  mounted() {
    this.spinner = true;
    fetch(this.api)
      .then((res) => res.json())
      .then((res) => {
        this.data = res
        this.bio = this.data.profile.webBio
        console.log('data',this.data)

        this.spinner = false
      });
  },
  watch: {
    $route(to, from) {
      // console.log("TO>", to, "FROM", from);
      // if(to.name == undefined) this.openPage = false
      // if(to.name != undefined) this.openPage = true

      if (to.path == "/") this.openPage = false;
      if (to.path != "/" && from.path == "/") this.openPage = true;
      if (to.name == "story" && from.name == undefined) this.openPage = false;
      console.clear()
    },
  },
  data() {
    return {
      data: false,
      bio: false,
      openPage: false,
      spinner: false,
      api:'https://script.google.com/macros/s/AKfycbxzQoNc-Hy4FtS9uhYazKvGkcnRpJFteL40pR3-4kYLtZLj2lUODR-3YuNSje0Yj44Bjw/exec'
    };
  },
  methods: {
    notify() {
      document
        .querySelector(".subscribers-modal-container")
        .classList.remove("subscribers-not-displayed");
      document
        .querySelector(".subscribers-modal-container")
        .classList.remove("subscribers-hide-animate");
    },
  },
};
</script>

<style>
.SlideModal-enter-active {
  -webkit-animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.SlideModal-leave-active {
  -webkit-animation: slide-out-left 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-left 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@media (min-width: 501px) {
  .SlideModal-enter-active {
    -webkit-animation: slide-in-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  .SlideModal-leave-active {
    -webkit-animation: slide-out-bottom 0.3s
      cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-out-bottom 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2022-5-16 1:46:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-5-16 1:47:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-5-13 14:45:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2022-5-13 14:46:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
}
.color-change-2x {
  -webkit-animation: 1.5s linear infinite alternate both;
  animation: color-change-2x 1.5s linear infinite alternate both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-5-14 1:53:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation color-change-2x
 * ----------------------------------------
 */
@-webkit-keyframes color-change-2x {
  0% {
    /* background: #19dcea; */
    background: orange;
  }
  100% {
    /* background: #b22cff; */
    background: deeppink;
  }
}
@keyframes color-change-2x {
  0% {
    background: #19dcea;
  }
  100% {
    background: #b22cff;
  }
}
</style>
