import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import swiperPage from '../components/swiper-box.vue'


const routes = [
  {
    path: '/swiper',
    name: 'swiper',
    component: swiperPage
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/faq-page.vue')
  },
  {
    path: '/web_extensions',
    name: 'web extensions',
    component: () => import('../views/web-extensions-page.vue')
  },
  {
    path: '/web_standards',
    name: 'web standards',
    component: () => import('../views/web-standards-page.vue')
  },
  {
    path: '/pwa',
    name: 'pwa',
    component: () => import('../views/pwa-page.vue')
  },
  {
    path: '/crm',
    name: 'crm',
    component: () => import('../views/crm-page.vue')
  },
  {
    path: '/business_emails',
    name: 'business emails',
    component: () => import('../views/business-emails-page.vue')
  },
  {
    path: '/partners_and_clients',
    name: 'partners and clients',
    component: () => import('../views/partners-and-clients-page.vue')
  },
  {
    path: '/story',
    name: 'story',
    component: () => import('../components/story.vue')
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('../views/share-page.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact-page.vue')
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
