<template>
  <div class="accordion">
    <div @click="open" class="accordion-header">
      <h1 class="acc-title">{{ title }}</h1>
      <div class="icons">
        <i v-if="closed" class="bi bi-chevron-down"></i>
        <i v-if="!closed" class="bi bi-chevron-up"></i>
      </div>
    </div>
    <transition name="slide">
      <div v-if="!closed" class="accordion-body">
        <!-- TEXT or ANYTHING -->

        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: ["title"],
  data() {
    return {
      head: "header",
      body: "body",
      closed: true,
    };
  },
  methods: {
    open() {
      this.closed = !this.closed;
    },
  },
};
</script>
<style>
.accordion {
  width: 75%;
  /* border: 2px dashed; */
  margin: 3rem auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}
.accordion-header {
  width: 100%;
  padding: 1rem 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-style: none;
  border-bottom-style: solid;
  border-width: 1px;
  cursor: pointer;
}
.acc-title {
  width: 90%;
  /* border: 1px solid; */
  line-height: 1.5;
  letter-spacing: 2px;
  color: #293241;
  user-select: none;
}
.accordion-body {
  width: 100%;
  padding: 1rem 0.5rem;
  transition: 1s;
}
.accordion-body > p {
  line-height: 1.5;
  color: #6c757d;
  user-select: none;
  text-align: center;
}

@media (max-width: 500px) {
  .accordion {
    width: 85%;
  }
}

.slide-enter-active {
  -webkit-animation: slide-in-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-leave-active {
  -webkit-animation: slide-out-top 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-top 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-5-13 14:31:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-2rem);
    transform: translateY(-2rem);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-2rem);
    transform: translateY(-2rem);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-5-13 14:32:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1rem);
    transform: translateY(-1rem);
    opacity: 0;
  }
}
@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1rem);
    transform: translateY(-1rem);
    opacity: 0;
  }
}
</style>