<template>
  <section class="modal-box">
    <!-- GET BACK ICON -->
    <slot></slot>

    <!-- <i @click="closeModal" class="bi bi-arrow-left"></i> -->
    <div class="modal-header">
      <!-- IMAGE OR STORY -->
      <slot name="story"></slot>

      <h1 class="modal-title">{{ title }}</h1>
    </div>
    <div class="modal-body">
      <!-- MODAL BODY -->
      <slot name="body"></slot>
    </div>
    <div class="modal-footer">
      <!-- MODAL ACTION BUTTON -->
      <slot name="modal-action"></slot>
    </div>
  </section>
</template>
<script>
export default {
  props: ["title"],
  data() {
    return {
      isopen: false,
    };
  },
};
</script>
<style>
.modal-box {
  width: 100%;
  height: 100%;
  background-color: #edf2fb;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}
.back-icon {
  font-size: 2rem;
  color: var(--dark);
  position: relative;
  top: 1rem;
  left: 1rem;
  cursor: pointer;
}
.modal-header {
  width: 100%;
  /* border: 1px solid; */
  /* margin: 0px auto 1rem auto; */
  margin: 1rem auto;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
}
.modal-header > i {
  font-size: 1rem;
}
.modal-title {
  width: 100%;
  /* border: 1px dotted;  */
  text-align: center;
  color: #4885ed;
  letter-spacing: 2px;
  font-size: 1.5rem;
  text-transform: uppercase;
  line-height: 1.5;
}
.modal-title::first-letter{
  text-transform: uppercase;
}


.modal-body {
  margin: 2rem auto;
}
@media (max-width: 500px) {
  /* .modal-box > i {
      font-size: 1.5rem;
    } */
}
</style>